import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, InputGroup, FormLabel, Row, Button } from "react-bootstrap";
import { Input } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { ActionMeta } from "react-select";
import {
  getFileExtension,
  getRestrictedFileMessage,
  isMaxUploadSizeLimitExceeded,
  isValidEmailAddress,
  validateAllFilesUploaded,
  validateFileSize,
  validateFileType,
} from "../helper/Validations";
import { IFileModel, IFileUploadModel } from "../core/viewModels/FileModel";
import AttachmentList from "./Attachments/AttachmentList";
import { AccessCodeModal } from "./AccessCodeModal";
import { AppNotifier } from "../helper/AppNotifier";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  DropOffConstants,
  DropOffMenuItems,
  DropoffSenderForm,
  LinkType,
  localStorageConstants,
  LogEventConstants,
} from "../helper/Constants";
import {
  fetchDropOffPageInfo,
  fetchUploadAttachmentSasLink,
  removeAttachment,
  sendDropOffMessage,
  validateDropOffLink,
  fetchDropOffSettings,
  fetchFolderDropOffRecipient,
  ValidateDropOffRecipient,
} from "../store/actions/dropOffActions";
import { useDispatch, useSelector } from "react-redux";
import {
  IDropOffPageInfo,
  IDropOffRecipient,
  IDropOffSettings,
  initialSentMessageRecipientModel,
  ISentMessageRecipientModel,
} from "../core/viewModels/DropffModel";
import { UploadFunctions } from "@sssuite-js-packages/file-utility";
import { DropOffType, RecipientType } from "../core/common/enums";
import { ApplicationState } from "../store";
import TinyMCEComponent from "./tinymce/TinyMCE";
import { CustomModal } from "./CustomModal";
import { logger } from "../App";
import AttachmentUploader from "./AttachmentUploader";
import {
  getFileName,
  getOneHubSenderDetails,
  getUserIdFromEmail,
  isIphoneDevice,
  isMobile,
} from "../helper/HelperFunctions";
import { FloatingLabel } from "react-bootstrap";
import {
  BackArrowIcon,
  CloseXButton,
  FileDropIcon,
} from "../common/icons/svgIcons";
import { ToasterMessages } from "../helper/ToasterMessages";
import {
  fetchDropOffSenderInfo,
  saveUserActivity,
} from "../store/actions/oneHubActions";
import { StepComponent } from "./common/StepContainer";
import Header from "./Layout/Header";
import { AxiosError } from "axios";
import {
  ExchangeFileType,
  OneHubEventType,
  ProductType,
} from "../core/common/clientPortalEnums";
import { IUserActivity } from "../core/viewModels/oneHub/usersActivity";

export interface IFormDataState {
  senderEmail: string;
  senderFirstName: string;
  senderLastName: string;
  recipients: ISentMessageRecipientModel[];
  subject: string;
  message: string;
  attachments: IFileModel[];
  isFormDirty: boolean;
  rememberMe: boolean;
  personalDropOffUserId: number;
}

const initialFormDataState: IFormDataState = {
  senderEmail: "",
  senderFirstName: "",
  senderLastName: "",
  recipients: [],
  subject: "",
  message: "",
  attachments: [],
  isFormDirty: false,
  rememberMe: false,
  personalDropOffUserId: 0,
};

const createOption = (value: string, label: string) => ({
  label,
  value,
});

const ShareFileCard: React.FC = (props: any) => {
  const menuKeys = DropOffMenuItems;
  const [activeMenu, setActiveMenu] = useState(menuKeys[0]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  const [formData, setFormData] =
    useState<IFormDataState>(initialFormDataState);
  const [confirmSend, setConfirmSend] = useState({
    show: false,
    sendAnyway: false,
    message: "",
  });
  const clientId = props.match.params.clientId;
  const encodedEmail = props.match.params.encodedEmail;
  const dispatch = useDispatch();
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recipients, setRecipients] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<IFileUploadModel[]>([]);
  const [recipientOptions, setRecipientOptions] = useState<any[]>([]);
  const [editorKey, setEditorKey] = useState("");
  const [isValidatingDropOffLink, setIsValidatingDropOffLink] = useState(true);
  const [isValidatingDropOffRecipient, setIsValidatingDropOffRecipient] =
    useState(false);
  const [showDragAndDrop, setShowDragAndDrop] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<number>();
  const [fromEmailErrorText, setFromEmailErrorText] = useState<string>("");
  const [fromFirstNameErrorText, setFromFirstNameErrorText] =
    useState<string>("");
  const [fromLastNameErrorText, setFromLastNameErrorText] =
    useState<string>("");
  const recipientRef = React.useRef<any>(null);
  const fileUploadUtilities = new UploadFunctions();
  const isAccessedFromOneHub = encodedEmail ? true : false;
  const documentsRef = React.useRef<IFileUploadModel[]>([]);
  const [showMoreRecipientsBtn, setShowMoreRecipientsBtn] = useState(false);
  const [showMoreRecipients, setShowMoreRecipients] = useState(false);
  const [showMoreAttachments, setShowMoreAttachments] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  documentsRef.current = attachments;

  const dropOffState = useSelector(
    (state: ApplicationState) => state.dropOffState
  );

  const oneHubState = useSelector(
    (state: ApplicationState) => state.oneHubState
  );
  const headerInfo = useSelector(
    (appState: ApplicationState) => appState.sendLink.headerInfo
  );

  const pageTitle = "Dropoff page";
  const isFolderDropOff = props.location.pathname.includes("FolderDropOff");
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    if ((value || value == "") && name && name != "") {
      setFormData((formData) => {
        return { ...formData, [name]: value, isFormDirty: true };
      });
      setSelectedField(0);

      switch (name) {
        case DropOffConstants.SENDER_EMAIL:
          setFromEmailErrorText("");
          break;
        case DropOffConstants.SENDER_FIRST_NAME:
          setFromFirstNameErrorText("");
          break;
        case DropOffConstants.SENDER_LAST_NAME:
          setFromLastNameErrorText("");
          break;
        default:
          break;
      }
    }
  };

  const handleRememberMeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = (event.target as HTMLInputElement)?.checked;
    setFormData({ ...formData, rememberMe: value });
    logger.trackEvent(
      logger.buildEvent(
        `${
          value
            ? LogEventConstants.Common.Checked
            : LogEventConstants.Common.Unchecked
        } ${pageTitle}: RememberMe updated`,
        {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
          recipients: formData.recipients,
          attachments: formData.attachments,
        }
      )
    );
  };

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${pageTitle} loaded`, {
        page: pageTitle,
        clientId: clientId,
      })
    );
    const dropOffType = isFolderDropOff
      ? DropOffType.FolderDropOff
      : DropOffType.DropOff;

    dispatch(
      validateDropOffLink(clientId, dropOffType, () => {
        props.history.push("/Error");
      })
    );

    dispatch(
      fetchDropOffPageInfo(
        clientId,
        dropOffType,
        (dropOffPageInfo: IDropOffPageInfo) => {
          handleDropOffPageInfo(dropOffPageInfo, dropOffType);
        }
      )
    );

    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const dropArea = document.getElementsByClassName("body-container")[0];

    if (dropArea) {
      dropArea.addEventListener("dragenter", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDragAndDrop(true);
      });

      dropArea.addEventListener("dragleave", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDragAndDrop(false);
      });

      dropArea.addEventListener("dragover", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDragAndDrop(true);
      });

      dropArea.addEventListener("drop", (e: any) => {
        if ((e?.target?.className).includes("drag-drop-container")) {
          setShowDragAndDrop(false);
        } else {
          e.preventDefault();
          setShowDragAndDrop(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    attachments.length > 0 && onUploadFile(attachments);
    if (documentsRef.current.length > 1) {
      !showMoreRecipients && setShowMoreAttachments(true);
      setShowMoreRecipients(false);
    }
  }, [documentsRef.current.length]);

  useEffect(() => {
    confirmSend.sendAnyway && handleSendFile();
  }, [confirmSend]);

  useEffect(() => {
    if (isAccessedFromOneHub && oneHubState.senderInfo.length > 0) {
      const localSenderEmail = localStorage.getItem(
        localStorageConstants.senderEmail
      );
      // if remember me not enabled or remembered sender email is not same as onehub sender email
      if (
        !localSenderEmail ||
        !oneHubState.senderInfo.some(
          (sender) => sender.emailAddress === localSenderEmail
        )
      ) {
        const senderInfo = getOneHubSenderDetails(
          oneHubState.senderInfo.find((sender) => sender.isPrimary)
        );
        setFormData((formData) => {
          return {
            ...formData,
            senderFirstName: senderInfo.firstName,
            senderLastName: senderInfo.lastName,
            senderEmail: senderInfo.emailAddress,
          };
        });
        setSenderDetailsLocal(false);
      }
    }
  }, [oneHubState.senderInfo]);

  useEffect(() => {
    if (recipients?.length > 0) {
      const sentRecipients: ISentMessageRecipientModel[] = [];
      recipients.map((item: any) => {
        sentRecipients.push({
          ...initialSentMessageRecipientModel,
          firstName: item.label, //item.firstName,
          lastName: item.label,
          email: item.value, //TO DO: need to check on this
          recipientType: RecipientType.ToRecipient,
          recipientId: item.userId || undefined,
        });
      });
      setFormData((formData) => {
        return {
          ...formData,
          recipients: sentRecipients,
        };
      });

      setShowMoreRecipientsBtn(recipients?.length > 1 && !showMoreRecipients);

      if (recipientRef.current && isMobile()) {
        const valueContainer = recipientRef.current.controlRef.querySelector(
          ".recipient__value-container"
        );
        if (valueContainer) {
          requestAnimationFrame(() => {
            valueContainer.scrollTop = 0;
          });
        }
      }
    }
  }, [recipients, recipients?.length]);

  const handleDropOffPageInfo = (
    dropOffPageInfo: IDropOffPageInfo,
    dropOffType: DropOffType
  ) => {
    if (dropOffType == DropOffType.DropOff) {
      dispatch(
        fetchDropOffSettings(
          clientId,
          dropOffType,
          (settingsResponse: IDropOffSettings) => {
            handleDropOffRecipients(dropOffPageInfo, settingsResponse);
          }
        )
      );
    } else {
      dispatch(
        fetchFolderDropOffRecipient(
          clientId,
          (recepientResponse: IDropOffRecipient) => {
            handleFolderDropOffRecipients(dropOffPageInfo, recepientResponse);
          }
        )
      );
    }
  };

  const handleDropOffRecipients = (
    pageInfoResponse: IDropOffPageInfo,
    settingsResponse: IDropOffSettings
  ) => {
    setIsValidatingDropOffLink(false);
    let personalDropOffRecipient;
    if (
      settingsResponse.isEmailAddressLookupAllowed &&
      pageInfoResponse.personalDropOffUserId === 0
    ) {
      const options = settingsResponse.dropOffRecipients.map(
        (item: IDropOffRecipient) =>
          createOption(
            `${item.emailAddress}`,
            item.firstName + " " + item.lastName
          )
      );

      const defaultRecipient = settingsResponse.dropOffRecipients.find(
        (x) => x.isDefault
      );
      if (defaultRecipient) setDefaultDropOffRecipient(defaultRecipient);
      setRecipientOptions(options);
    } else {
      if (settingsResponse.isPersonalDropOffLinkToDisplay) {
        personalDropOffRecipient = settingsResponse.dropOffRecipients.find(
          (x) => x.userId === pageInfoResponse.personalDropOffUserId
        );
        if (personalDropOffRecipient) {
          setDefaultDropOffRecipient(personalDropOffRecipient);
        }
      }
    }

    if (
      pageInfoResponse.personalDropOffUserId !== 0 &&
      (!settingsResponse.isPersonalDropOffLinkToDisplay ||
        !personalDropOffRecipient)
    ) {
      props.history.push("/Error");
    }

    if (encodedEmail && oneHubState.senderInfo?.length === 0) {
      dispatch(
        fetchDropOffSenderInfo(clientId, encodedEmail, LinkType.CompanyDropOff)
      );
    }

    const senderDetails = getSenderDetailsLocal();
    setFormData((formData) => {
      return {
        ...formData,
        senderFirstName: senderDetails.senderFirstName,
        senderLastName: senderDetails.senderLastName,
        senderEmail: senderDetails.senderEmail,
        rememberMe: senderDetails.isRememberMe,
        personalDropOffUserId: pageInfoResponse.personalDropOffUserId,
      };
    });
  };

  const handleFolderDropOffRecipients = (
    pageInfoResponse: IDropOffPageInfo,
    dropOffRecipients: IDropOffRecipient
  ) => {
    setIsValidatingDropOffLink(false);
    setDefaultDropOffRecipient(dropOffRecipients);

    setFormData((formData) => {
      return {
        ...formData,
        senderFirstName:
          localStorage.getItem(localStorageConstants.senderFirstName) ?? "",
        senderLastName:
          localStorage.getItem(localStorageConstants.senderLastName) ?? "",
        senderEmail:
          localStorage.getItem(localStorageConstants.senderEmail) ?? "",
        rememberMe: localStorage.getItem(localStorageConstants.senderEmail)
          ? true
          : false,
        personalDropOffUserId: pageInfoResponse.personalDropOffUserId,
      };
    });
  };

  const handleSendFile = () => {
    if (validateMessage()) {
      const sentRecepients: ISentMessageRecipientModel[] = [];

      recipients.map((item: any) => {
        sentRecepients.push({
          ...initialSentMessageRecipientModel,
          firstName: item.label, //item.firstName,
          lastName: item.label,
          email: item.value, //TO DO: need to check on this
          recipientType: RecipientType.ToRecipient,
          recipientId: item.userId || undefined,
        });
      });

      const tempAttachments: IFileModel[] = attachments.map((item) => ({
        fileId: item.fileId,
        fileGuid: item.guid,
        isUploadComplete: item.isUploaded,
        name: getFileName(item.file.name),
        size: item.fileSize ?? item.file.size,
        uploadProgress: item.uploadProgress * 100,
      }));

      setSenderDetailsLocal(formData.rememberMe);

      const updatedFormData = {
        ...formData,
        recipients: sentRecepients,
        attachments: tempAttachments,
      };

      setFormData(updatedFormData);

      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: send message clicked`, {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
          recipients: sentRecepients,
          attachments: tempAttachments,
        })
      );
      if (isAccessedFromOneHub) {
        handleSendMessage(
          isFolderDropOff ? DropOffType.FolderDropOff : DropOffType.DropOff,
          updatedFormData,
          isAccessedFromOneHub
        );
      } else if (!isMobile()) {
        setShowAccessCodeModal(true);
      }
    }
  };

  const validateSenderDetails = () => {
    if (
      !formData.senderEmail &&
      !formData.senderFirstName &&
      !formData.senderLastName
    ) {
      AppNotifier.Warning(ToasterMessages.WARNING.EMPTY_SENDER);
      return false;
    }
    if (!formData.senderEmail) {
      setFromEmailErrorText(DropOffConstants.EMAIL_EMPTY_WARNING);
      setSelectedField(DropoffSenderForm.EMAIL);
      return false;
    }
    if (!isValidEmailAddress(formData.senderEmail)) {
      setFromEmailErrorText(ToasterMessages.WARNING.INVALID_EMAIL);
      setSelectedField(DropoffSenderForm.EMAIL);
      return false;
    }
    if (
      !formData.senderFirstName ||
      (formData.senderFirstName && formData.senderFirstName.trim() === "")
    ) {
      setFromFirstNameErrorText(DropOffConstants.FIRST_NAME_EMPTY_WARNING);
      setSelectedField(DropoffSenderForm.FIRST_NAME);
      return false;
    }
    if (
      !formData.senderLastName ||
      (formData.senderLastName && formData.senderLastName.trim() === "")
    ) {
      setFromLastNameErrorText(DropOffConstants.LAST_NAME_EMPTY_WARNING);
      setSelectedField(DropoffSenderForm.LAST_NAME);
      return false;
    }
    return true;
  };

  const validateMessage = () => {
    if (validateSenderDetails()) {
      if (recipients?.length === 0) {
        AppNotifier.Warning(ToasterMessages.WARNING.EMPTY_RECIPIENTS);
        return;
      }

      if (!formData.subject) {
        AppNotifier.Warning(ToasterMessages.WARNING.EMPTY_SUBJECT);
        return;
      }

      if (!formData.message) {
        AppNotifier.Warning(ToasterMessages.WARNING.EMPTY_MESSAGE);
        return;
      }

      if (attachments?.length === 0 && !confirmSend.sendAnyway) {
        setConfirmSend({
          show: true,
          sendAnyway: false,
          message: DropOffConstants.WITHOUT_ATTACHMENT_WARNING,
        });
        return;
      }

      if (!validateAllFilesUploaded(attachments)) {
        AppNotifier.Warning(ToasterMessages.WARNING.UPLOAD_ATTACHMENT_PROGRESS);
        return;
      }
      return true;
    }
  };

  const handleSendMessage = (
    dropOffType: DropOffType,
    updatedFormData: IFormDataState,
    isAccessedFromClientPortal?: boolean
  ) => {
    const tempAttachments: IFileModel[] = attachments.map((item) => ({
      fileId: item.fileId,
      fileGuid: item.guid,
      isUploadComplete: item.isUploaded,
      name: getFileName(item.file.name),
      size: item.fileSize ?? item.file.size,
      uploadProgress: item.uploadProgress * 100,
    }));

    updatedFormData.attachments = tempAttachments;

    if (updatedFormData.recipients.length > 0) {
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: sending message`, {
          count: 1,
          page: pageTitle,
          clientId: clientId,
          senderEmail: updatedFormData.senderEmail,
          personalDropOffUserId: updatedFormData.personalDropOffUserId,
          recipients: updatedFormData.recipients,
          attachments: updatedFormData.attachments,
          dropOffType: dropOffType,
        })
      );
      dispatch(
        sendDropOffMessage(
          updatedFormData,
          clientId,
          dropOffType,
          dropOffType === DropOffType.FolderDropOff,
          handleSuccess,
          handleError,
          isAccessedFromClientPortal
        )
      );
    }
  };

  const setSenderDetailsLocal = (rememberMe: boolean) => {
    if (rememberMe) {
      localStorage.setItem(
        localStorageConstants.senderFirstName,
        formData.senderFirstName
      );
      localStorage.setItem(
        localStorageConstants.senderLastName,
        formData.senderLastName
      );
      localStorage.setItem(
        localStorageConstants.senderEmail,
        formData.senderEmail
      );
    } else {
      localStorage.removeItem(localStorageConstants.senderFirstName);
      localStorage.removeItem(localStorageConstants.senderLastName);
      localStorage.removeItem(localStorageConstants.senderEmail);
    }
  };

  const getSenderDetailsLocal = () => {
    const isRememberMe = localStorage.getItem("senderEmail") ? true : false;
    let senderFirstName = "";
    let senderLastName = "";
    let senderEmail = "";
    if (isRememberMe) {
      senderFirstName = localStorage.getItem(
        localStorageConstants.senderFirstName
      );
      senderLastName = localStorage.getItem(
        localStorageConstants.senderLastName
      );
      senderEmail = localStorage.getItem(localStorageConstants.senderEmail);
    } else if (isAccessedFromOneHub && oneHubState.senderInfo.length > 0) {
      const senderInfo = getOneHubSenderDetails(
        oneHubState.senderInfo.find((sender) => sender.isPrimary)
      );
      senderFirstName = senderInfo.firstName;
      senderLastName = senderInfo.lastName;
      senderEmail = senderInfo.emailAddress;
    }

    return { senderFirstName, senderLastName, senderEmail, isRememberMe };
  };

  const handleSuccess = (
    clientResponse: any,
    isAccessedFromClientPortal?: boolean
  ) => {
    if (clientResponse.isSuccess) {
      AppNotifier.Success(ToasterMessages.SUCCESS.SEND_MESSAGE);
      // Mobile Specific Case Starts
      setActiveMenuIndex(0);
      setActiveMenu(menuKeys[0]);
      // Mobile Specific Case Ends
      if (isAccessedFromClientPortal && attachments.length > 0) {
        saveUserActivityActivityLog(formData, clientResponse.data);
      }
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: message sent successfully`, {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
          recipients: formData.recipients,
          attachments: formData.attachments,
        })
      );
      const senderDetails = getSenderDetailsLocal();
      setFormData({
        ...initialFormDataState,
        senderFirstName: senderDetails.senderFirstName,
        senderLastName: senderDetails.senderLastName,
        senderEmail: senderDetails.senderEmail,
        rememberMe: senderDetails.isRememberMe,
        personalDropOffUserId:
          dropOffState.dropOffPageInfo.personalDropOffUserId,
      });
      if (dropOffState.dropOffPageInfo.personalDropOffUserId !== 0) {
        const selectedUser =
          dropOffState.dropOffSettings.dropOffRecipients.find(
            (x) =>
              x.userId === dropOffState.dropOffPageInfo.personalDropOffUserId
          );
        if (selectedUser) setDefaultDropOffRecipient(selectedUser);
      } else if (dropOffState.dropOffSettings.isEmailAddressLookupAllowed) {
        const defaultRecipient =
          dropOffState.dropOffSettings.dropOffRecipients.find(
            (x) => x.isDefault
          );
        if (defaultRecipient) setDefaultDropOffRecipient(defaultRecipient);
        else setRecipients([]);
      } else setRecipients([]);
      setAttachments([]);

      setConfirmSend({ show: false, sendAnyway: false, message: "" });

      const key = (+new Date()).toString();
      setEditorKey(key);
    } else {
      if (clientResponse.errorCode === DropOffConstants.ERROR_DROP_OFF) {
        AppNotifier.Error(clientResponse.errorDescription);
      } else {
        logger.trackEvent(
          logger.buildEvent(`${pageTitle}: message sending failed`, {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: formData.recipients,
            attachments: formData.attachments,
          })
        );
        props.history.push("/Error");
      }
    }
  };

  const saveUserActivityActivityLog = async (
    formData: IFormDataState,
    messageGuid: any
  ) => {
    let userActivity: IUserActivity = {
      eventId: OneHubEventType.SendFilesToCPA,
      eventData: {
        documentId: 0,
        fileName: formData.subject,
        signerType: 0,
        messageGuid: messageGuid,
        userName: getSenderDetailsLocal().senderEmail,
        exchangeFileType: ExchangeFileType.SentMessage,
      },
      productType: ProductType.Exchange,
    };
    await saveUserActivity(
      clientId,
      encodedEmail,
      LinkType.CompanyDropOff,
      userActivity
    );
  };
  const handleError = (error: AxiosError) => {
    AppNotifier.Error(ToasterMessages.ERROR.SEND_MESSAGE);
    if (error.response?.status === 400) {
      setAttachments([]);
    }
  };

  const setDefaultDropOffRecipient = (selectedUser: IDropOffRecipient) => {
    const option = createOption(
      `${selectedUser.emailAddress}`,
      selectedUser.firstName + " " + selectedUser.lastName
    );
    const recipients: ISentMessageRecipientModel[] = [
      {
        ...initialSentMessageRecipientModel,
        firstName: option.label,
        lastName: option.label,
        email: option.value,
        recipientType: RecipientType.ToRecipient,
        recipientId: selectedUser.userId || undefined,
      },
    ];
    setRecipients([option]);
    setFormData((formData) => {
      return {
        ...formData,
        recipients: recipients,
      };
    });
  };

  const handleOnDropFiles = (e: any, callback?: any) => {
    e.preventDefault();
    e.stopPropagation();
    const newFiles: IFileUploadModel[] = [...attachments];
    const files: File[] = e.target.files || e.dataTransfer.files;
    const { restrictedFileTypes } = dropOffState.dropOffSettings;
    let isFileInValid = true;
    let totalSize = 0;
    Array.from(files).forEach((item: File) => {
      totalSize += item.size;
    });

    attachments.forEach((attachment) => {
      totalSize += attachment.file.size;
    });
    const filesArray = Array.from(files);
    for (let i = 0; i < filesArray.length; i++) {
      const item = filesArray[i];
      const fileType = getFileExtension(item.name);
      if (!validateFileSize(item)) {
        break;
      }
      if (!isMaxUploadSizeLimitExceeded(totalSize)) {
        break;
      }
      if (validateFileType(fileType, restrictedFileTypes) || isFolderDropOff) {
        newFiles.push({
          file: item,
          uploadProgress: 0,
          guid: uuidv4(),
          isUploaded: false,
          fileName: getFileName(item.name),
        });
      } else {
        isFileInValid = false;
      }
    }
    !isFolderDropOff &&
      !isFileInValid &&
      AppNotifier.Warning(getRestrictedFileMessage(restrictedFileTypes));
    setAttachments(newFiles);
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const onBrowseFiles = (e: any, callback?: any) => {
    handleOnDropFiles(e, callback);
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: files browsed`, {
        page: pageTitle,
        clientId: clientId,
        senderEmail: formData.senderEmail,
        personalDropOffUserId: formData.personalDropOffUserId,
        recipients: formData.recipients,
      })
    );
  };

  const onUploadFile = (documents: IFileUploadModel[]) => {
    for (let i = 0; i < documents.length; i++) {
      if (!documents[i].isUploaded) {
        const fileName = getFileName(documents[i].file.name);
        dispatch(
          fetchUploadAttachmentSasLink(
            documents[i].guid,
            clientId,
            isFolderDropOff ? DropOffType.FolderDropOff : DropOffType.DropOff,
            (data: any) => {
              fileUploadUtilities.uploadFile(
                documents[i].file,
                data,
                fileName,
                uploadProgressCallback,
                uploadCompleteCallback
              );
            }
          )
        );

        logger.trackEvent(
          logger.buildEvent(`${pageTitle}: upload file started`, {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: JSON.stringify(formData.recipients),
            fileInfo: {
              name: documents[i].file.name,
              type: documents[i].file.type,
              size: documents[i].file.size,
              uploadProgress: documents[i].uploadProgress,
              guid: documents[i].guid,
              isUploaded: documents[i].isUploaded,
            },
          })
        );
      }
    }
  };

  const uploadProgressCallback = (percent: number, fileToUpload: any) => {
    const updatedState = documentsRef.current.map((item) => {
      if (item.guid === fileToUpload.fileGUID) {
        item.uploadProgress = percent / 100;
        if (percent == 100) {
          item.isUploaded = true;
        }
      }
      return item;
    });
    setAttachments(updatedState);
  };

  const uploadCompleteCallback = (fileToUpload: any, errorMessage?: string) => {
    if (fileToUpload.uploadFailed) {
      const validAttachments = documentsRef.current.filter(
        (file) => file.guid != fileToUpload.fileGUID
      );
      if (
        errorMessage &&
        validAttachments.length < documentsRef.current.length
      ) {
        AppNotifier.Warning(errorMessage);
      }
      setAttachments(validAttachments);
      return;
    }

    const updatedState = attachments.map((item) => {
      if (item.guid === fileToUpload.fileGUID && !fileToUpload.uploadFailed) {
        item.uploadProgress = 1;
        item.isUploaded = true;

        logger.trackEvent(
          logger.buildEvent(`${pageTitle}: upload file completed`, {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: JSON.stringify(formData.recipients),
            fileInfo: {
              name: item.file.name,
              type: item.file.type,
              size: item.file.size,
              uploadProgress: item.uploadProgress,
              guid: item.guid,
              isUploaded: item.isUploaded,
            },
          })
        );
      }
      return item;
    });
    setAttachments(updatedState);
  };

  const handleOnRemoveAttachment = (guid: string) => {
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: remove attachment clicked`, {
        page: pageTitle,
        clientId: clientId,
        senderEmail: formData.senderEmail,
        personalDropOffUserId: formData.personalDropOffUserId,
        recipients: JSON.stringify(formData.recipients),
        fileGuid: guid,
      })
    );

    dispatch(
      removeAttachment(
        guid,
        clientId,
        isFolderDropOff ? DropOffType.FolderDropOff : DropOffType.DropOff,
        () => {
          let updatedFiles: IFileUploadModel[] = [...attachments];
          updatedFiles = updatedFiles.filter((x) => x.guid !== guid);
          setAttachments(updatedFiles);

          logger.trackEvent(
            logger.buildEvent(`${pageTitle}: remove attachment succeeded`, {
              page: pageTitle,
              clientId: clientId,
              senderEmail: formData.senderEmail,
              personalDropOffUserId: formData.personalDropOffUserId,
              recipients: JSON.stringify(formData.recipients),
              fileGuid: guid,
              attachments: updatedFiles,
            })
          );
        }
      )
    );
  };

  const handleToChange = (newValue: any, data: ActionMeta<any>) => {
    switch (data.action) {
      case "select-option":
      case "create-option":
        let enteredEmail = data.option.value;
        if (enteredEmail && isValidEmailAddress(enteredEmail)) {
          if (!dropOffState.dropOffSettings.isEmailAddressLookupAllowed) {
            validateRecipientOnAddressLookupOff(enteredEmail);
          } else if (checkIfEmailExistsInDropOffRecipients(enteredEmail)) {
            const isLabelHasEmailId = isValidEmailAddress(data.option.label);
            const userInfo = getUserInfo(
              isLabelHasEmailId ? data.option.label : data.option.value
            )[0];
            const newRecipient: IDropOffRecipient = {
              firstName:
                userInfo?.firstName ?? data.option.label.split(" ")[0] ?? "",
              lastName:
                userInfo?.lastName ?? data.option.label.split(" ")[1] ?? "",
              emailAddress: enteredEmail,
              userId: getUserIdFromEmail(
                dropOffState.dropOffSettings.dropOffRecipients,
                enteredEmail
              ),
              isDefault: false,
            };
            const option = createOption(
              newRecipient.emailAddress,
              userInfo?.firstName + " " + userInfo?.lastName
            );
            setRecipients([...recipients, option]);
          } else {
            AppNotifier.Warning(ToasterMessages.WARNING.NO_USER_FOUND);
          }
        } else {
          AppNotifier.Warning(ToasterMessages.WARNING.INVALID_EMAIL);
        }
        break;
      case "remove-value":
      case "pop-value":
        if (data && data.removedValue) {
          const emailAddress = data.removedValue.value;
          let filteredRecipients = recipients.filter(
            (x) => x.value !== emailAddress
          );
          setRecipients(filteredRecipients);
        }
        break;
    }
  };

  const checkIfEmailExistsInDropOffRecipients = (email: string) => {
    return dropOffState.dropOffSettings.dropOffRecipients.some(
      (x) => x.emailAddress === email
    );
  };

  const validateRecipientOnAddressLookupOff = (email: string) => {
    setIsValidatingDropOffRecipient(true);
    dispatch(
      ValidateDropOffRecipient(
        clientId,
        DropOffType.DropOff,
        email,
        handleRecipientValidation
      )
    );
  };

  const handleRecipientValidation = (
    response: boolean,
    enteredEmail: string
  ) => {
    if (response) {
      const newRecipient: IDropOffRecipient = {
        firstName: "",
        lastName: "",
        emailAddress: enteredEmail,
        userId: 0,
        isDefault: false,
      };
      const option = createOption(
        `${newRecipient.emailAddress}`,
        newRecipient.emailAddress
      );
      setRecipients([...recipients, option]);
      setIsValidatingDropOffRecipient(false);
    } else {
      AppNotifier.Warning(ToasterMessages.WARNING.NO_USER_FOUND);
      setIsValidatingDropOffRecipient(false);
    }
  };

  const getUserInfo = (email: string) => {
    return dropOffState?.dropOffSettings?.dropOffRecipients.filter(
      (user) => user.emailAddress === email
    );
  };

  const handleOnHideConfirmSend = () => {
    logger.trackEvent(
      logger.buildEvent(
        `${LogEventConstants.Common.Close} ${LogEventConstants.Dropoff.SendWithoutAttachmentConfirmationModal.Title}`,
        {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
        }
      )
    );
    setConfirmSend({ show: false, sendAnyway: false, message: "" });
  };

  const handleMessageBodyChange = (value: any) => {
    setFormData({ ...formData, message: value });
    setShowMoreRecipientsBtn(true);
    setShowMoreRecipients(false);
  };

  const getLoaderMessage = () => {
    if (dropOffState.isSending) {
      return DropOffConstants.SENDING_MESSAGE_LOADER_TEXT;
    } else {
      return DropOffConstants.LOADING_LOADER_TEXT;
    }
  };

  const onOneHubSenderEmailChange = (e: any) => {
    const selectedEmail = e.target.value;
    const rememberedEmail = localStorage.getItem(
      localStorageConstants.senderEmail
    );
    let senderFirstName = "";
    let senderLastName = "";
    if (rememberedEmail === selectedEmail) {
      senderFirstName = localStorage.getItem(
        localStorageConstants.senderFirstName
      );
      senderLastName = localStorage.getItem(
        localStorageConstants.senderLastName
      );
    } else {
      const selectedSender = oneHubState.senderInfo.find(
        (sender) => sender.emailAddress === selectedEmail
      );
      const senderInfo = getOneHubSenderDetails(selectedSender);
      senderFirstName = senderInfo.firstName;
      senderLastName = senderInfo.lastName;
    }
    setFormData({
      ...formData,
      senderFirstName,
      senderLastName,
      senderEmail: selectedEmail,
      isFormDirty: true,
    });
  };

  const handleAttachmentSubmit = () => {
    logger.trackEvent(
      logger.buildEvent(
        `${LogEventConstants.Dropoff.SendWithoutAttachmentConfirmationModal.Title}: submit clicked`,
        {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
        }
      )
    );
    setConfirmSend({ show: false, sendAnyway: true, message: "" });

    if (isMobile() && activeMenuIndex === 1) {
      setActiveMenuIndex(2);
      setActiveMenu(menuKeys[2]);
    }
  };

  const handleGoBack = () => {
    // Update the state to go back to the previous step
    if (activeMenuIndex === 1) {
      // Screen 2
      setActiveMenuIndex(0);
      setActiveMenu(menuKeys[0]);
    }
    if (activeMenuIndex === 2) {
      // Screen 3
      setActiveMenuIndex(1);
      setActiveMenu(menuKeys[1]);
      if (attachments.length === 0) {
        setConfirmSend({ show: false, sendAnyway: false, message: "" });
      }
    }
  };

  const handleGoNext = () => {
    // Update the state to go to the next step
    if (activeMenuIndex === 0) {
      // Screen 1
      if (validateSenderDetails()) {
        setActiveMenuIndex(1);
        setActiveMenu(menuKeys[1]);
      }
    }
    if (activeMenuIndex === 1) {
      // Screen 2
      if (validateMessage()) {
        setActiveMenuIndex(2);
        setActiveMenu(menuKeys[2]);
      }
    }
  };

  const handleShowMoreRecipientsClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setShowMoreRecipientsBtn(false);
    setShowMoreRecipients(true);
  };

  const getShowMoreText = () => {
    if (
      recipients &&
      recipients[0]?.label?.length + recipients[1]?.label?.length > 14 &&
      recipients?.length > 1 &&
      windowDimensions.width < 380
    ) {
      return "+" + (recipients.length - 1) + " more";
    }
    if (
      recipients &&
      recipients[0]?.label?.length > 11 &&
      recipients?.length > 1
    ) {
      return "+" + (recipients.length - 1) + " more";
    }
    if (recipients?.length > 2) {
      return "+" + (recipients.length - 2) + " more";
    }
  };

  const handleMobileSendMessageClick = async (dropOffType: DropOffType) => {
    await handleSendFile();
    await handleSendMessage(dropOffType, formData);
  };

  return (
    <LoadingOverlay className="share-file-card-wrapper">
      {isMobile() ? (
        <div
          className={`drop-off-mobile-view ${
            isIphoneDevice() ? "iphone-devices" : ""
          }`}
          onClick={() => {
            setShowMoreRecipientsBtn(true);
            setShowMoreRecipients(false);
          }}
        >
          <Row className={"drop-Off-wizard-container"}>
            <Header
              headerInfo={headerInfo}
              clientId={clientId}
              encodedEmail={encodedEmail}
              hideProductLogo={true}
              hideUTELink={true}
              className="header-2"
              showAlternateProfileIcon
            >
              <StepComponent
                menuKeys={menuKeys}
                activeMenuIndex={activeMenuIndex}
              />
            </Header>
            <div className="back-arrow-header-btn" onClick={handleGoBack}>
              {activeMenuIndex > 0 && <BackArrowIcon />}
            </div>
          </Row>

          {
            // Screen 1
            activeMenuIndex === 0 && (
              <div>
                <div className="from-container">
                  <h5>{DropOffConstants.FROM_TITLE}</h5>
                  <span className="from-sub-text">
                    {DropOffConstants.ENTER_INFORMATION}
                  </span>
                  <div className="to-form-container">
                    <Form onSubmit={handleGoNext}>
                      <div className="input-container">
                        <FormLabel>{DropOffConstants.LABEL_EMAIL}</FormLabel>
                        {isAccessedFromOneHub ? (
                          <Form.Select
                            value={formData.senderEmail}
                            onChange={onOneHubSenderEmailChange}
                          >
                            {oneHubState.senderInfo?.map((sender) => (
                              <option
                                key={sender.emailAddress}
                                value={sender.emailAddress}
                              >
                                {sender.emailAddress}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            type="email"
                            name="senderEmail"
                            className={`form-input-text primary-input ${
                              fromEmailErrorText?.length > 0 &&
                              formData.senderEmail.length >= 0
                                ? "error"
                                : ""
                            }`}
                            placeholder={DropOffConstants.PLACEHOLDER_EMAIL}
                            onChange={handleOnChange}
                            value={formData.senderEmail}
                          ></Form.Control>
                        )}
                        {fromEmailErrorText && (
                          <div className="invalid-feedback">
                            {fromEmailErrorText}
                          </div>
                        )}
                      </div>
                      <div className="input-container">
                        <FormLabel>
                          {DropOffConstants.LABEL_FIRST_NAME}
                        </FormLabel>
                        <Form.Control
                          type="text"
                          name="senderFirstName"
                          className={`form-input-text ${
                            fromFirstNameErrorText?.length > 0 &&
                            formData.senderFirstName.length >= 0
                              ? "error"
                              : ""
                          }`}
                          placeholder={DropOffConstants.PLACEHOLDER_FIRST_NAME}
                          maxLength={DropOffConstants.FIRST_NAME_MAXLENGTH}
                          onChange={handleOnChange}
                          value={formData.senderFirstName}
                        ></Form.Control>
                        {fromFirstNameErrorText && (
                          <div className="invalid-feedback">
                            {fromFirstNameErrorText}
                          </div>
                        )}
                      </div>
                      <div className="input-container">
                        <FormLabel>
                          {DropOffConstants.LABEL_LAST_NAME}
                        </FormLabel>
                        <Form.Control
                          type="text"
                          name="senderLastName"
                          className={`form-input-text ${
                            fromLastNameErrorText?.length > 0 &&
                            formData.senderLastName.length >= 0
                              ? "error"
                              : ""
                          }`}
                          placeholder={DropOffConstants.PLACEHOLDER_LAST_NAME}
                          maxLength={DropOffConstants.FIRST_NAME_MAXLENGTH}
                          onChange={handleOnChange}
                          value={formData.senderLastName}
                        ></Form.Control>
                        {fromLastNameErrorText && (
                          <div className="invalid-feedback">
                            {fromLastNameErrorText}
                          </div>
                        )}
                      </div>
                    </Form>
                  </div>
                  <div className="from-footer-links-container">
                    <span>
                      <Input
                        id="remember-me"
                        type="checkbox"
                        name="rememberMe"
                        className="primary-checkbox"
                        checked={formData.rememberMe}
                        onChange={handleRememberMeChange}
                      ></Input>
                      <span className="remember-me-text">
                        {DropOffConstants.REMEMBER_ME}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="footer-btn-container">
                  <Button
                    className="button-primary-blue-2 step-1-send"
                    onClick={handleGoNext}
                  >
                    {DropOffConstants.NEXT_SEND_TEXT}
                  </Button>
                </div>
              </div>
            )
          }

          {
            // Screen 2
            activeMenuIndex === 1 && (
              <div className="to-container">
                <Form>
                  <div
                    className={`to-top-container  ${
                      showMoreRecipients ? "max-recipient-container" : ""
                    }
              ${
                recipients?.length <= 1 && attachments.length < 2
                  ? "max-message-container"
                  : ""
              }
              `}
                  >
                    <InputGroup className="to-recipient-group">
                      <FormLabel>{DropOffConstants.TO_TITLE}</FormLabel>
                      <div
                        className={`to-recipent-container`}
                        onClick={(e) => {
                          if (recipients?.length > 2) {
                            setShowMoreRecipientsBtn(false);
                            setShowMoreRecipients(true);
                            setShowMoreAttachments(false);
                          }
                        }}
                      >
                        <CreatableSelect
                          isMulti={true}
                          isClearable={false}
                          placeholder=""
                          classNamePrefix="recipient"
                          ref={recipientRef}
                          blurInputOnSelect={false}
                          hideSelectedOptions={false}
                          allowCreateWhileLoading={true}
                          options={recipientOptions}
                          onChange={handleToChange}
                          isDisabled={isFolderDropOff ? true : false}
                          formatCreateLabel={(value: any) => value}
                          theme={(theme: any) => {
                            return { ...theme, borderRadius: 0 };
                          }}
                          components={{
                            DropdownIndicator: null,
                            MultiValueRemove: (props) => (
                              <span
                                className="recipient-multi-value-remove-icon"
                                onClick={props.innerProps.onClick}
                                onTouchEnd={props.innerProps.onTouchEnd}
                              >
                                <CloseXButton width={10} height={10} />
                              </span>
                            ),
                          }}
                          styles={creatableStyle}
                          value={recipients}
                          isLoading={isLoading}
                        />
                      </div>
                      {showMoreRecipientsBtn && (
                        <span
                          className="more-recipients-btn"
                          onClick={handleShowMoreRecipientsClick}
                        >
                          {getShowMoreText()}
                        </span>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <Form.Control
                        placeholder="Subject"
                        name="subject"
                        className=""
                        maxLength={DropOffConstants.SUBJECT_MAXLENGTH}
                        onChange={handleOnChange}
                        value={formData.subject}
                      ></Form.Control>
                    </InputGroup>
                    <div
                      className="body-container"
                      onClick={() => {
                        setShowMoreRecipientsBtn(true);
                        setShowMoreRecipients(false);
                      }}
                    >
                      <FormLabel>{DropOffConstants.LABEL_MESSAGE}</FormLabel>
                      <TinyMCEComponent
                        messageBody={formData.message}
                        changeStateTinymceBody={(e) =>
                          handleMessageBodyChange(e)
                        }
                        readOnly={0}
                        key={editorKey}
                      />
                    </div>
                  </div>
                  <div
                    className={`droppoff__attachments ${
                      (showMoreAttachments && recipients?.length <= 2) ||
                      (recipients?.length > 2 && showMoreRecipientsBtn)
                        ? "max-attachment-container"
                        : ""
                    }`}
                  >
                    <AttachmentList
                      attachments={attachments}
                      showAttachmentIcon
                      showAttachmentSize
                      onRemoveAttachment={handleOnRemoveAttachment}
                    />
                  </div>
                  <div className="to-bottom-container">
                    <AttachmentUploader onDropFiles={onBrowseFiles} />
                    <button
                      className="button-primary-blue-2"
                      onClick={(e) => {
                        e.preventDefault();
                        handleGoNext();
                      }}
                    >
                      {DropOffConstants.SEND_TEXT}
                    </button>
                  </div>
                </Form>
              </div>
            )
          }

          {
            // Screen 3
            activeMenuIndex === 2 && (
              <AccessCodeModal
                showState={showAccessCodeModal}
                dropOffType={
                  isFolderDropOff
                    ? DropOffType.FolderDropOff
                    : DropOffType.DropOff
                }
                sender={() => {
                  return {
                    emailAddress: formData.senderEmail,
                    firstName: formData.senderFirstName,
                    lastName: formData.senderLastName,
                    userId: 0,
                    isDefault: false,
                  };
                }}
                clientId={clientId}
                onHide={() => {
                  setShowAccessCodeModal(!showAccessCodeModal);
                  setConfirmSend({
                    show: false,
                    sendAnyway: false,
                    message: "",
                  });
                  logger.trackEvent(
                    logger.buildEvent(
                      `${LogEventConstants.Common.Close}  ${LogEventConstants.Dropoff.AccessCodeModal.Title}`,
                      {
                        count: 1,
                        page: pageTitle,
                        clientId: props.clientId,
                        senderEmail: formData.senderEmail,
                        personalDropOffUserId: formData.personalDropOffUserId,
                      }
                    )
                  );
                }}
                onSendMessage={(dropOffType) => {
                  handleMobileSendMessageClick(dropOffType);
                }}
              />
            )
          }
        </div>
      ) : (
        <div className="sharefilecard-container">
          <div className="from-container">
            <h5>{DropOffConstants.FROM_TITLE}</h5>
            <div className="to-form-container">
              <div className="input-container">
                <FormLabel>{DropOffConstants.LABEL_EMAIL}</FormLabel>
                {isAccessedFromOneHub ? (
                  <Form.Select
                    value={formData.senderEmail}
                    onChange={onOneHubSenderEmailChange}
                  >
                    {oneHubState.senderInfo?.map((sender) => (
                      <option
                        key={sender.emailAddress}
                        value={sender.emailAddress}
                      >
                        {sender.emailAddress}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Form.Control
                    type="email"
                    name="senderEmail"
                    className={`form-input-text primary-input ${
                      fromEmailErrorText?.length > 0 &&
                      formData.senderEmail.length >= 0
                        ? "error"
                        : ""
                    }`}
                    placeholder={DropOffConstants.PLACEHOLDER_EMAIL}
                    onChange={handleOnChange}
                    value={formData.senderEmail}
                  ></Form.Control>
                )}
                {fromEmailErrorText && (
                  <div className="invalid-feedback">{fromEmailErrorText}</div>
                )}
              </div>
              <div className="input-container">
                <FormLabel>{DropOffConstants.LABEL_FIRST_NAME}</FormLabel>
                <Form.Control
                  type="text"
                  name="senderFirstName"
                  className={`form-input-text ${
                    fromFirstNameErrorText?.length > 0 &&
                    formData.senderFirstName.length >= 0
                      ? "error"
                      : ""
                  }`}
                  placeholder={DropOffConstants.PLACEHOLDER_FIRST_NAME}
                  maxLength={DropOffConstants.FIRST_NAME_MAXLENGTH}
                  onChange={handleOnChange}
                  value={formData.senderFirstName}
                ></Form.Control>
                {fromFirstNameErrorText && (
                  <div className="invalid-feedback">
                    {fromFirstNameErrorText}
                  </div>
                )}
              </div>
              <div className="input-container">
                <FormLabel>{DropOffConstants.LABEL_LAST_NAME}</FormLabel>
                <Form.Control
                  type="text"
                  name="senderLastName"
                  className={`form-input-text ${
                    fromLastNameErrorText?.length > 0 &&
                    formData.senderLastName.length >= 0
                      ? "error"
                      : ""
                  }`}
                  placeholder={DropOffConstants.PLACEHOLDER_LAST_NAME}
                  maxLength={DropOffConstants.FIRST_NAME_MAXLENGTH}
                  onChange={handleOnChange}
                  value={formData.senderLastName}
                ></Form.Control>
                {fromLastNameErrorText && (
                  <div className="invalid-feedback">
                    {fromLastNameErrorText}
                  </div>
                )}
              </div>
            </div>
            <div className="from-footer-links-container">
              <span>
                <Input
                  id="remember-me"
                  type="checkbox"
                  name="rememberMe"
                  className="primary-checkbox"
                  checked={formData.rememberMe}
                  onChange={handleRememberMeChange}
                ></Input>
                <span>{DropOffConstants.REMEMBER_ME}</span>
              </span>
            </div>
          </div>
          <div
            className={`to-container ${
              attachments?.length > 4 && recipients.length >= 5
                ? "to-container-more-files"
                : ""
            }`}
          >
            <h5>{DropOffConstants.TO_TITLE}</h5>
            <Form>
              <div className="to-top-container">
                <InputGroup>
                  <div className="to-recipent-container">
                    <CreatableSelect
                      isMulti={true}
                      isClearable={false}
                      placeholder="Add Recipients"
                      classNamePrefix="recipient"
                      blurInputOnSelect={false}
                      hideSelectedOptions={false}
                      allowCreateWhileLoading={true}
                      options={recipientOptions}
                      onChange={handleToChange}
                      isDisabled={isFolderDropOff ? true : false}
                      formatCreateLabel={(value: any) => value}
                      theme={(theme: any) => {
                        return { ...theme, borderRadius: 0 };
                      }}
                      components={{
                        DropdownIndicator: null,
                        MultiValueRemove: (props) => (
                          <span
                            className="recipient-multi-value-remove-icon"
                            onClick={props.innerProps.onClick}
                            onTouchEnd={props.innerProps.onTouchEnd}
                          >
                            <CloseXButton width={10} height={10} />
                          </span>
                        ),
                      }}
                      styles={creatableStyle}
                      value={recipients}
                      isLoading={isLoading}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <FloatingLabel controlId="floatingInput" label="Subject">
                    <Form.Control
                      placeholder="Subject"
                      name="subject"
                      maxLength={DropOffConstants.SUBJECT_MAXLENGTH}
                      onChange={handleOnChange}
                      value={formData.subject}
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
                <div className="body-container">
                  <TinyMCEComponent
                    messageBody={formData.message}
                    changeStateTinymceBody={(e) => handleMessageBodyChange(e)}
                    readOnly={0}
                    key={editorKey}
                    onDragOver={() => setShowDragAndDrop(true)}
                  />
                  <div
                    className={`drag-drop-container ${
                      !showDragAndDrop ? "hide" : ""
                    }`}
                    onDrop={(e) => {
                      logger.trackEvent(
                        logger.buildEvent(`${pageTitle}: file(s) dropped`, {
                          page: pageTitle,
                          clientId: clientId,
                          senderEmail: formData.senderEmail,
                          personalDropOffUserId: formData.personalDropOffUserId,
                          recipients: formData.recipients,
                        })
                      );
                      handleOnDropFiles(e);
                    }}
                  >
                    <FileDropIcon />
                    <span>{DropOffConstants.DRAG_AND_DROP_TEXT}</span>
                    <span className="drag-drop-helper-text">
                      {DropOffConstants.DRAG_AND_DROP_SUB_TEXT}
                    </span>
                  </div>
                </div>
              </div>
              <div className="droppoff__attachments">
                <AttachmentList
                  attachments={attachments}
                  onRemoveAttachment={handleOnRemoveAttachment}
                />
              </div>
              <div className="to-bottom-container">
                <AttachmentUploader onDropFiles={onBrowseFiles} />
                <button
                  className="button-primary-blue-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSendFile();
                  }}
                >
                  <i className="fas fa-paper-plane"></i>
                  {DropOffConstants.SEND_TEXT}
                </button>
              </div>
            </Form>
          </div>
          {showAccessCodeModal && (
            <AccessCodeModal
              showState={showAccessCodeModal}
              dropOffType={
                isFolderDropOff
                  ? DropOffType.FolderDropOff
                  : DropOffType.DropOff
              }
              sender={() => {
                return {
                  emailAddress: formData.senderEmail,
                  firstName: formData.senderFirstName,
                  lastName: formData.senderLastName,
                  userId: 0,
                  isDefault: false,
                };
              }}
              clientId={clientId}
              onHide={() => {
                setShowAccessCodeModal(!showAccessCodeModal);
                setConfirmSend({ show: false, sendAnyway: false, message: "" });
                logger.trackEvent(
                  logger.buildEvent(
                    `${LogEventConstants.Common.Close}  ${LogEventConstants.Dropoff.AccessCodeModal.Title}`,
                    {
                      count: 1,
                      page: pageTitle,
                      clientId: props.clientId,
                      senderEmail: formData.senderEmail,
                      personalDropOffUserId: formData.personalDropOffUserId,
                    }
                  )
                );
              }}
              onSendMessage={(dropOffType) =>
                handleSendMessage(dropOffType, formData)
              }
            />
          )}
        </div>
      )}

      <CustomModal
        show={confirmSend.show}
        onSubmit={handleAttachmentSubmit}
        title="Send Message"
        className="send-without-attachment-confirmation-modal"
        confirmButtonName="Send"
        cancelButtonName="Cancel"
        onHide={handleOnHideConfirmSend}
        isConfirmModal={true}
      >
        {DropOffConstants.WITHOUT_ATTACHMENT_WARNING}
      </CustomModal>
      <Loader
        loading={
          dropOffState.isSending ||
          isValidatingDropOffLink ||
          isValidatingDropOffRecipient ||
          oneHubState.isFetchingSenderInfo
        }
        text={getLoaderMessage()}
      />
    </LoadingOverlay>
  );
};

export default ShareFileCard;

const creatableStyle = {
  control: (base: any) => ({
    ...base,
    border: "none",
    borderBottom: "solid 1px #ced4da",
    minHeight: "32px",
    fontSize: "14px",
    boxShadow: "none",
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: "13px",
  }),
};
