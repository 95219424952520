import React from "react";
import axios from "axios";
import { Route, Switch, useHistory } from "react-router";
import Layout from "./components/Layout";
import { ErrorPage } from "./components/Error/Error";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-overlay-loader/styles.css";
import DropOff from "./components/DropOff/DropOff";
import { default as FolderDropOff } from "./components/DropOff/DropOff";
import AccessLinkLayout from "./components/AccessLinkLayout/AccessLinkLayout";
import { inMemoryToken, isMobile } from "./helper/HelperFunctions";
import {
  initializeAppInsights,
  TelemetryLogger,
} from "./common/Logger/AppInsights";
import {
  LinkType,
  localStorageConstants,
  sessionStorageConstants,
} from "./helper/Constants";
import "./styles/global.scss";
import { Notification } from "./components/common/Notification";
export const logger = TelemetryLogger.getInstance();
export const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;
const clientId =
  sessionStorage.getItem(sessionStorageConstants.uteClientId) ||
  localStorage.getItem("clientId");

axios.interceptors.request.use(function (config: any) {
  const clientId =
    sessionStorage.getItem(sessionStorageConstants.uteClientId) ||
    localStorage.getItem("clientId");
  const accessToken =
    inMemoryToken.get(clientId) ||
    getUteAccessToken() ||
    localStorage.getItem(localStorageConstants.accessToken);
  const isApiUrl = config.url && config.url.includes("/api/");
  if (!(config.url.includes("https://") || config.url.includes("http://"))) {
    config.url =
      API_BASE_URL +
      (config.url.includes("/api")
        ? config.url
        : config.url.replace("api", "/api"));
  }

  if (accessToken && isApiUrl) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

const getUteAccessToken = () => {
  return sessionStorage.getItem(sessionStorageConstants.accessTokenForUte);
};

function RouteWrapper({
  component: Component,
  layout: Layout,
  type,
  name,
  ...rest
}: any) {
  let id = rest.computedMatch.params.clientId ?? rest.computedMatch.params.id;
  initializeAppInsights(id, name, type);

  return (
    <Route
      {...rest}
      render={(props) =>
        Layout ? (
          <Layout {...props} customClass={rest.customClass}>
            <Component {...props} />
            <Notification />
          </Layout>
        ) : (
          <>
            <Component {...props} />
            <Notification />
          </>
        )
      }
    />
  );
}

export const App: React.FC<any> = (props) => {
  const history = useHistory();

  axios.interceptors.response.use(
    function (response: any) {
      const actionName = response?.config.url;
      if (response.status === 401) {
        logger.trackWarning(
          `${actionName} failed for client: ${clientId}, error: UnAuthorized`
        );
        throw new Error("UnAuthorized");
      }
      return response;
    },
    function (error: any) {
      if (!error.config?.__isRetryRequest && error.response) {
        error.config.__isRetryRequest = true;
        const actionName = error.response.config?.url;

        if (error.response.status == "401") {
          logger.trackWarning(
            `${actionName} failed for client: ${clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`
          );
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <Switch>
        <RouteWrapper
          exact
          path="/SendLinkRedirect/:clientId"
          component={(props) => (
            <AccessLinkLayout type={LinkType.SendLink} {...props} />
          )}
          layout={Layout}
          customClass="access-card-container"
          type={LinkType.SendLink}
        />
        <RouteWrapper
          exact
          path="/Dropoff/:clientId/:encodedEmail?"
          component={DropOff}
          name="DropOff"
          layout={Layout}
          customClass={
            isMobile() ? "dropoff-mobile-layout" : "dropoff-layout-container"
          }
        />
        <RouteWrapper
          exact
          path="/FolderDropOff/:clientId"
          component={FolderDropOff}
          name="FolderDropOff"
          layout={Layout}
          customClass={
            isMobile() ? "dropoff-mobile-layout" : "dropoff-layout-container"
          }
        />
        <RouteWrapper
          exact
          path="/DocumentRequest/:clientId/:fromUte?"
          component={(props) => (
            <AccessLinkLayout type={LinkType.DocumentRequestLink} {...props} />
          )}
          layout={Layout}
          customClass="access-card-container"
          type={LinkType.DocumentRequestLink}
        />
        <RouteWrapper
          exact
          path="/Error"
          component={ErrorPage}
          layout={Layout}
        />
        <Route exact path="/" component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </>
  );
};

App.displayName = App.name;

export default App;
